<template>
  <div>
    <p class="text-2xl mb-6">Ventas por mesoneros por productos</p>
     
    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <dos-fechas @getFechas="getFechas"></dos-fechas>
          </v-col>
          <v-col cols="12" sm="6">
            <select-puesto
              :storePuesto="false"
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true" 
               :filtros="{id_reporte:4}"
            ></select-puesto> 
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <SelectCategoria
              :clearable="true"
              v-model="filtros.id_categoria"
              :add="false"
              :dense="true"
              :outlined="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectSubCategoria
              :clearable="true"
              v-model="filtros.id_sub_categoria"
              :padre="filtros.id_categoria"
              :add="false"
              :dense="true"
              :outlined="true"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
              <BuscarProducto   @GetProducto="GetProducto" />
            </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
   
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue'
import { ref } from '@vue/composition-api'
import DosFechas from '@/components/DosFechas.vue'
import SelectPuesto from '../../../archivo/puesto/components/SelectPuestoMulti.vue'
import MaestroService from '@/api/servicios/MaestroService'
import funciones from '@/funciones/funciones'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCategoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import moment from 'moment'
import store from '@/store'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProducto.vue'
// demos

export default {
  components: { TablaReporte, DosFechas, SelectPuesto, SelectCategoria, SelectSubCategoria,BuscarProducto },
  setup() {
    const filtrosInit = {
      id_categoria: null,
      id_sub_categoria: null,
      id_puesto: [],
      id_mesonero: null,
      id_producto: null,
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
    }

    const filtros = ref(JSON.parse(JSON.stringify(filtrosInit)))

    const nombreReporte = ref(`Total productos entre fechas`)
    const GetProducto = dato => { 
      filtros.value.id_producto = dato.id
      console.log('GetProducto', dato)
    }
    const cabeceraExcel = ref([
      {
        puesto: 'Locatario/Area',
        desde: 'Desde',
        hasta: 'Hasta',
        nombre: 'Producto',
        cant: 'Cant',
        total: 'Total',
      },
    ])

    const cabecera = ref([
      {
        text: 'Mesonero',
        align: 'start',
        sortable: false,
        value: 'mesonero_nombre',
      },
      { text: 'Desde', value: 'desde' },
      { text: 'Hasta', value: 'hasta' },
      { text: 'Producto', value: 'nombre' },
      { text: 'Categoria', value: 'categoria' },
      { text: 'Sub Categoria', value: 'sub_categoria' },
      { text: 'Cant', value: 'cant' },
      { text: 'Total', value: 'total' }, 
      { text: '%', value: 'por_venta' }, 
    ])
    const categoria = ref(null)
    const sub_categoria = ref(null)
    const datos = ref([])
    const fechas = ref({
      desde:  moment().format('YYYY-MM-DD'),
      hasta:  moment().format('YYYY-MM-DD')
    })
    const cargando = ref(false)
    const puesto = ref({})
    const validar = () => {
      let validado = true
       
      if (fechas.value.desde == undefined || fechas.value.desde == '') {
        validado = false
      }

      return validado
    }
    const totales = ref({
      total: 0,
      iva: 0,
      total_iva: 0,
    })
    const totalizar = () => {
      let totales_ = {
        total: 0,
        iva: 0,
        total_iva: 0,
      }
      datos.value.forEach(element => {
        totales_.total += element.total
        totales_.iva += element.iva
        totales_.total_iva += element.total_iva
      })

      totales.value = totales_
    }
    const buscarDatos = () => {
      cargando.value = true

      var parametros = { ...filtros.value, desde: fechas.value.desde, hasta: fechas.value.hasta,id_puesto: filtros.value.id_puesto?.join(','),}
      MaestroService.rptVetasMesoneroProducto(parametros)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Ventas por mesonero por productos  ${fechas.value.desde} al ${fechas.value.hasta}`
           
            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else { 
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const getFechas = datos => {
      fechas.value = datos
    }
    const getPuesto = datos => {
      puesto.value = datos
    }
    return {
      cabecera,
      datos,
      getFechas,
      getPuesto,
      fechas,
      puesto,
      validar,
      buscarDatos,
      cargando,
      cabeceraExcel,
      nombreReporte,
      categoria,
      sub_categoria,
      totalizar,
      totales,
      funciones,
      filtros,
      GetProducto
    }
  },
}
</script>
